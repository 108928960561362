<template>
  <v-app class="transparent">
    <v-container
      id="regular-tables"
      fluid
      tag="section">

      <base-v-component heading="Create Sheet"/>

      <base-material-card
        icon="mdi-google-spreadsheet"
        title="Create Sheet"
        class="px-5 py-3">

        <v-form ref="form" @submit="submit" onSubmit="return false;">
          <v-container class="py-0">
            <v-row>
              <v-col
                cols="12"
                md="4">
                <v-text-field
                  v-model="sheet.name"
                  label="Sheet Name"
                  :rules="rules.name"
                  placeholder="Sheet Name"
                  outlined
                  required
                />
              </v-col>
              <v-col
                cols="12"
                md="5">
                <v-file-input
                  v-model="sheet.document"
                  color="primary"
                  label="Document"
                  placeholder="Select your document"
                  prepend-icon="mdi-paperclip"
                  outlined
                  accept=".xlsx,.xlsm,.xltx,.xltm"
                  :rules="rules.document"
                  :show-size="1000">
                  <template v-slot:selection="{ text }">
                    <v-chip color="primary accent-4" dark label>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn class="ma-2" color="info" :href="require('../../assets/sample.xlsx')" download>
                  <v-icon left>mdi-download</v-icon>
                  Download Sample
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                class="text-right">
                <v-btn
                  color="success"
                  class="mr-0"
                  @click="submit">
                  Add Sheet
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>


      </base-material-card>


    </v-container>
  </v-app>
</template>


<script>

  export default {
    name: "CreateSheet",
    created() {
    },
    data() {
      return {
        sheet: {
          name: '',
          document: null
        },
        rules: {
          name: [
            v => !!v || 'Sheet Name is required',
            v => v.length >= 5 || 'Name must be at least 5 characters',
          ],
          document: [
            value => !!value || 'Document Field is required',
            value => !value || value.size < 2000000 || 'Sheet size should be less than 2 MB!',
          ],
        },
      }
    },
    methods: {
      submit() {
        if (this.$refs.form.validate()) {
          let formData = new FormData();
          formData.append('name', this.sheet.name);
          formData.append('document', this.sheet.document);
          this.$authApi.post(`/user-sheets`, formData).then(res => {
            this.flashMessage.success({
              title: 'Sheet Created Successfully',
              message: `Your sheet ${this.sheet.name} has been created successfully`
            });
            this.$router.push('/sheets')
          }).catch(error => {
            if (error.response) {
              let errorList = '';
              this.flashMessage.error({
                title: 'Something went wrong ',
                message: error.response.data.errors.document.join(',')
              });
            }
          })
        }
      },

    }
  }
</script>
